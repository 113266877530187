import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../../config/URL";
import { MaterialReactTable } from "material-react-table";
import { ThemeProvider, createTheme, Menu } from "@mui/material";

const Contacted = ({ fetchLeadCounts }) => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [updatingRowId, setUpdatingRowId] = useState(null);

  const getData = async () => {
    try {
      const response = await api.get("/getAllContactUs");
      setDatas(response.data);
      console.log("response.data::", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row, index) => index + 1,
        header: "S.NO",
        enableSorting: true,
        enableHiding: true,
        size: 20,
        cell: ({ cell }) => (
          <span style={{ textAlign: "center" }}>{cell.getValue()}</span>
        ),
      },
      {
        accessorKey: "name",
        enableHiding: false,
        header: "Name",
        size: 60,
        Cell: ({ row }) => (
          <>
            <div className="d-flex justify-content-start align-items-center">
              {row.original.name} &nbsp;&nbsp;
              {row.original.isNew && (
                <div className="newCircle">
                  <span className="badge text-bg-danger">New</span>
                </div>
              )}
            </div>
          </>
        ),
      },
      {
        accessorKey: "email",
        enableHiding: true,
        header: "Email",
        size: 60,
      },
      { accessorKey: "message", header: "Message", enableHiding: true },
      { accessorKey: "createdBy", header: "Created By" },
      {
        accessorKey: "createdAt",
        header: "Created At",
        cell: ({ cell }) => cell.getValue()?.substring(0, 10),
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        cell: ({ cell }) => cell.getValue()?.substring(0, 10) || "",
      },
      {
        accessorKey: "updatedBy",
        header: "Updated By",
        cell: ({ cell }) => cell.getValue() || "",
      },
    ],
    []
  );

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#535454 !important",
            backgroundColor: "#e6edf7 !important",
            fontWeight: "400 !important",
            fontSize: "13px !important",
            textAlign: "center !important",
          },
        },
      },
    },
  });

  const handleClick = async (selectedId) => {
    setUpdatingRowId(selectedId);
    try {
      const response = await api.put(`/updateContactUs/${selectedId}`, {
        isNew: false,
      });
      if (response.status === 200) {
        getData();
        fetchLeadCounts();
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error.message || "An error occurred");
    } finally {
      setUpdatingRowId(null); // Clear the updating state
    }
  };

  const handleMenuClose = () => setMenuAnchor(null);

  return (
    <div className="container-fluid my-4 center">
      <ol
        className="breadcrumb my-3"
        style={{ listStyle: "none", padding: 0, margin: 0 }}
      >
        <li>
          <Link to="/" className="custom-breadcrumb">
            Home
          </Link>
          <span className="breadcrumb-separator"> &gt; </span>
        </li>
        <li>
          Lead Management
          <span className="breadcrumb-separator"> &gt; </span>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Contact
        </li>
      </ol>

      <div className="card">
        <div
          className="mb-3 d-flex justify-content-between align-items-center p-1"
          style={{ background: "#f5f7f9" }}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <div className="dot active"></div>
            </div>
            <span className="me-2 text-muted">
              This database shows the list of{" "}
              <span className="bold" style={{ color: "#287f71" }}>
                Contact
              </span>
            </span>
          </div>
        </div>

        {loading ? (
          <div className="loader-container">
            <div className="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (
          <>
            <ThemeProvider theme={theme}>
              <MaterialReactTable
                columns={columns}
                data={datas}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                initialState={{
                  pagination: { pageSize: 50, pageIndex: 0 },
                  columnVisibility: {
                    createdBy: false,
                    createdAt: false,
                    updatedBy: false,
                    updatedAt: false,
                  },
                }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    handleClick(row.original.id).then(() => {
                      // Navigate after the API call is successful
                      // navigate(`/lead/lead/view/${row.original.id}`);
                    });
                  },
                  style: { cursor: "pointer" },
                })}
              />
            </ThemeProvider>

            <Menu
              id="action-menu"
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Contacted;
