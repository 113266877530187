import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import api from "../../config/URL";
import fetchAllCoursesWithIdsC from "../List/CourseListByCenter";
import fetchAllClassesWithIdsC from "../List/ClassListByCourse";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const validationSchema = Yup.object().shape({
  courseId: Yup.string().required("*Select a Current Course"),
  classId: Yup.string().required("*Select a Current Class"),
  lastLessonDate: Yup.string().required("*Select a Last Lesson"),
  reason: Yup.string().required("*Select a Reason"),
  centerRemark: Yup.string()
    .required("*Leave Reason is required")
    .max(200, "*The maximum length is 200 characters"),
});

const StudentChangeClassAdd = ({ onsuccess, id, centerId, courseId, classId }) => {
  const [courseData, setCourseData] = useState(null);
  const [classData, setClassData] = useState(null);
  const [show, setShow] = useState(false);
  const [loadIndicator, setLoadIndicator] = useState(false);
  const [batchData, setBatchData] = useState(null);
  const [lastLessonDateData, setLastLessonDateData] = useState(null);
  const userName = localStorage.getItem("userName");

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
    fetchData();
    // Set initial values when modal opens
    formik.setFieldValue("courseId", courseId || "");
    formik.setFieldValue("classId", classId || "");
    if (courseId) fetchClasses(courseId);
    if (classId) fetchlastLessonData(classId);
  };

  const formik = useFormik({
    initialValues: {
      centerId: centerId || "",
      courseId: courseId || "",
      classId: classId || "",
      studentId: id,
      status: "PENDING",
      lastLessonDate: "",
      preferdays: "",
      preferTiming: "",
      preferStartDate: "",
      reason: "",
      otherReason: "",
      centerRemark: "",
      parentRemark: "",
      createdBy: userName,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoadIndicator(true);
      try {
        const response = await api.post("/createChangeClass", values, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 201) {
          toast.success(response.data.message);
          handleClose();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setLoadIndicator(false);
      }
    },
  });

  const handleDayChange = (e) => {
    formik.setFieldValue("preferdays", e.target.value);
  };

  const handleClassChange = (e) => {
    const newClassId = e.target.value;
    formik.setFieldValue("classId", newClassId);
    if (newClassId) {
      fetchlastLessonData(newClassId);
    }
  };

  const fetchBatchandTeacherData = async (day) => {
    try {
      const response = await api.get(`getTeacherWithBatchListByDay?day=${day}`);
      setBatchData(response.data.batchList);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchlastLessonData = async (selectedClassId) => {
    try {
      const response = await api.get(
        `getLastLessonClassDate?classId=${selectedClassId}&studentId=${id}`
      );
      setLastLessonDateData(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchData = async () => {
    try {
      const course = await fetchAllCoursesWithIdsC(centerId);
      setCourseData(course);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCourseChange = (event) => {
    const newCourseId = event.target.value;
    formik.setFieldValue("courseId", newCourseId);
    formik.setFieldValue("classId", ""); // Reset classId when course changes
    fetchClasses(newCourseId);
  };

  const fetchClasses = async (selectedCourseId) => {
    try {
      const classes = await fetchAllClassesWithIdsC(selectedCourseId);
      setClassData(classes);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (centerId) {
      formik.setFieldValue("centerId",centerId);
    }
    if (formik.values.preferdays) {
      fetchBatchandTeacherData(formik.values.preferdays);
    }
  }, [formik.values.preferdays ,centerId]);

  const formatTo12Hour = (time) => {
    const [hours, minutes] = time.split(":");
    let period = "AM";
    let hour = parseInt(hours, 10);

    if (hour === 0) {
      hour = 12;
    } else if (hour >= 12) {
      period = "PM";
      if (hour > 12) hour -= 12;
    }

    return `${hour}:${minutes} ${period}`;
  };

  const normalizeTime = (time) => {
    if (time.includes("AM") || time.includes("PM")) {
      return time;
    }
    return formatTo12Hour(time);
  };

  const convertTo24Hour = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours < 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-success btn-sm"
        style={{ fontSize: "12px" }}
        onClick={handleShow}
      >
        Change Class
      </button>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="headColor">Student Change Class</p>
          </Modal.Title>
        </Modal.Header>
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !formik.isSubmitting) {
              e.preventDefault();
            }
          }}
        >
          <div className="container-fluid p-3">
            <div className="row">
              <div className="col-md-6 col-12 mb-4">
                <label>
                  Current Course <span className="text-danger">*</span>
                </label>
                <select
                  name="courseId"
                  value={formik.values.courseId}
                  onChange={handleCourseChange}
                  onBlur={formik.handleBlur}
                  className={`form-select ${
                    formik.touched.courseId && formik.errors.courseId
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Course</option>
                  {courseData &&
                    courseData.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.courseNames}
                      </option>
                    ))}
                </select>
                {formik.touched.courseId && formik.errors.courseId && (
                  <div className="invalid-feedback">
                    {formik.errors.courseId}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label>
                  Current Class<span className="text-danger">*</span>
                </label>
                <select
                  name="classId"
                  value={formik.values.classId}
                  onChange={handleClassChange}
                  onBlur={formik.handleBlur}
                  className={`form-select ${
                    formik.touched.classId && formik.errors.classId
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Class</option>
                  {classData &&
                    classData.map((classItem) => (
                      <option key={classItem.id} value={classItem.id}>
                        {classItem.classNames}
                      </option>
                    ))}
                </select>
                {formik.touched.classId && formik.errors.classId && (
                  <div className="invalid-feedback">
                    {formik.errors.classId}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label>Prefer Day</label>
                <select
                  name="preferdays"
                  value={formik.values.preferdays}
                  onChange={handleDayChange}
                  onBlur={formik.handleBlur}
                  className={`form-select ${
                    formik.touched.preferdays && formik.errors.preferdays
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Day</option>
                  <option value="MONDAY">MONDAY</option>
                  <option value="TUESDAY">TUESDAY</option>
                  <option value="WEDNESDAY">WEDNESDAY</option>
                  <option value="THURSDAY">THURSDAY</option>
                  <option value="FRIDAY">FRIDAY</option>
                  <option value="SATURDAY">SATURDAY</option>
                  <option value="SUNDAY">SUNDAY</option>
                </select>
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label >Prefer Time</label>
                <select
                  name="preferTiming"
                  value={formik.values.preferTiming}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-select"
                >
                  <option value="">Select Time</option>
                  {batchData &&
                    batchData.map((time) => {
                      const displayTime = normalizeTime(time);
                      const valueTime =
                        time.includes("AM") || time.includes("PM")
                          ? convertTo24Hour(time)
                          : time;
                      return (
                        <option key={time} value={valueTime}>
                          {displayTime}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label>
                  Last Lesson Date<span className="text-danger">*</span>
                </label>
                <select
                  name="lastLessonDate"
                  value={formik.values.lastLessonDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-select ${
                    formik.touched.lastLessonDate &&
                    formik.errors.lastLessonDate
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Date</option>
                  {lastLessonDateData &&
                    lastLessonDateData.map((lastLessonDate) => (
                      <option key={lastLessonDate.id} value={lastLessonDate.id}>
                        {lastLessonDate.startDate}
                      </option>
                    ))}
                </select>
                {formik.touched.lastLessonDate &&
                  formik.errors.lastLessonDate && (
                    <div className="invalid-feedback">
                      {formik.errors.lastLessonDate}
                    </div>
                  )}
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label>
                  Reason<span className="text-danger">*</span>
                </label>
                <select
                  name="reason"
                  value={formik.values.reason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-select ${
                    formik.touched.reason && formik.errors.reason
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Reason</option>
                  <option value="Withdraw">Withdraw</option>
                  <option value="Graduate">Graduate</option>
                </select>
                {formik.touched.reason && formik.errors.reason && (
                  <div className="invalid-feedback">{formik.errors.reason}</div>
                )}
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label>Other Reason</label>
                <textarea
                  name="otherReason"
                  value={formik.values.otherReason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${
                    formik.touched.otherReason && formik.errors.otherReason
                      ? "is-invalid"
                      : ""
                  }`}
                ></textarea>
                {formik.touched.otherReason && formik.errors.otherReason && (
                  <div className="invalid-feedback">
                    {formik.errors.otherReason}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-12 mb-4">
                <label>Parent Remark</label>
                <textarea
                  name="parentRemark"
                  value={formik.values.parentRemark}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${
                    formik.touched.parentRemark && formik.errors.parentRemark
                      ? "is-invalid"
                      : ""
                  }`}
                ></textarea>
                {formik.touched.parentRemark && formik.errors.parentRemark && (
                  <div className="invalid-feedback">
                    {formik.errors.parentRemark}
                  </div>
                )}
              </div>
              <div className="col-12 mb-4">
                <label>
                  Centre Remark<span className="text-danger">*</span>
                </label>
                <textarea
                  name="centerRemark"
                  value={formik.values.centerRemark}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`form-control ${
                    formik.touched.centerRemark && formik.errors.centerRemark
                      ? "is-invalid"
                      : ""
                  }`}
                ></textarea>
                {formik.touched.centerRemark && formik.errors.centerRemark && (
                  <div className="invalid-feedback">
                    {formik.errors.centerRemark}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal.Footer className="mt-3">
            <Button
              className="btn btn-sm btn-border bg-light text-dark"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <button
              type="submit"
              className="btn btn-button btn-sm"
              disabled={loadIndicator}
            >
              {loadIndicator && (
                <span
                  className="spinner-border spinner-border-sm me-2"
                  aria-hidden="true"
                ></span>
              )}
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default StudentChangeClassAdd;