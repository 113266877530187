import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import Logo from "../../assets/images/Logo-Portal_Access.png";
import api from "../../config/URL";
import { PiBuildings, PiBookOpenText } from "react-icons/pi";
import { GiExitDoor } from "react-icons/gi";
import { HiOutlineUserGroup } from "react-icons/hi2";
import {
  TbUserSearch,
  TbCalendarTime,
  TbFolderCog,
  TbMessageCode,
} from "react-icons/tb";
import { LiaFileInvoiceDollarSolid, LiaUserEditSolid } from "react-icons/lia";
import { VscReferences } from "react-icons/vsc";
import { BsFileEarmarkRichtext } from "react-icons/bs";
import { GrUserSettings } from "react-icons/gr";
import { RiDashboardLine } from "react-icons/ri";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

const iconMapping = {
  "Centre Management": <PiBuildings />,
  "Course Management": <PiBookOpenText />,
  "Lead Management": <GiExitDoor />,
  Staffing: <HiOutlineUserGroup />,
  "Student Management": <TbUserSearch />,
  Schedule: <TbCalendarTime />,
  "Document Management": <TbFolderCog />,
  "Invoice and Payment": <LiaFileInvoiceDollarSolid />,
  "Referal Management": <VscReferences />,
  "Report Management": <BsFileEarmarkRichtext />,
  "Content Management": <LiaUserEditSolid />,
  Messaging: <TbMessageCode />,
  Settings: <GrUserSettings />,
};

function Sidebar({ leadCounts }) {
  const [menuItems, setMenuItems] = useState([]);
  const [data, setData] = useState({});
  const [openMenus, setOpenMenus] = useState({});
  const location = useLocation();
  const storedScreens = JSON.parse(localStorage.getItem("screens") || "{}");

  // Fetch initial data
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/getAllHeaderSavePublish`);
        setData(response.data);
      } catch (error) {
        console.error("Error Fetching Data: " + error.message);
      }
    };
    getData();
  }, []);

  // Initialize menu items
  useEffect(() => {
    const updatedMenuItems = [
      {
        title: "Centre Management",
        subMenus: [
          {
            title: "Centre Listing",
            path: "/center",
            access: storedScreens.centerListingIndex,
          },
        ],
      },
      {
        title: "Course Management",
        subMenus: [
          {
            title: "Subject",
            path: "/subject",
            access: storedScreens.subjectIndex,
          },
          { title: "Level", path: "/level", access: storedScreens.levelIndex },
          {
            title: "Course",
            path: "/course",
            access: storedScreens.courseIndex,
          },
          { title: "Class", path: "/class", access: storedScreens.classIndex },
        ],
      },
      {
        title: "Lead Management",
        totalCount: leadCounts.newLeadsCount + leadCounts.newContactsCount,
        subMenus: [
          {
            title: "Lead Listing",
            path: "lead/lead",
            access: storedScreens.leadListingIndex,
            count: leadCounts.newLeadsCount,
          },
          {
            title: "Contacts",
            path: "/lead/contacted",
            access: storedScreens.enrollmentIndex,
            count: leadCounts.newContactsCount,
          },
        ],
      },
      {
        title: "Staffing",
        subMenus: [
          { title: "Staff", path: "/staff", access: storedScreens.staffIndex },
          {
            title: "Teacher",
            path: "/teacher",
            access: storedScreens.teacherIndex,
          },
          {
            title: "Attendance",
            path: "/staffing/attendance",
            access: storedScreens.staffAttendanceIndex,
          },
          {
            title: "Check Attendance",
            path: "/staffing/check",
            access: storedScreens.checkAttendanceIndex,
          },
          {
            title: "Leave",
            path: "/leaveadmin",
            access: storedScreens.leaveAdminIndex,
          },
          {
            title: "Leave Request",
            path: "/leave",
            access: storedScreens.leaveIndex,
          },
          {
            title: "Holiday",
            path: "/holiday",
            access: storedScreens.holidayIndex,
          },
          {
            title: "Deduction",
            path: "/deduction",
            access: storedScreens.deductionIndex,
          },
          {
            title: "Payroll",
            path: "/payrolladmin",
            access: storedScreens.payrollIndex,
          },
          {
            title: "Payslip",
            path: "/employeepayslip",
            access: storedScreens.payslipIndex,
          },
          {
            title: "Freelancer Invoice",
            path: "/freelancerPayslip",
            access: storedScreens.freeLancerIndex,
          },
          {
            title: "Role & Matrix",
            path: "/role/add",
            access: storedScreens.rolesMatrixIndex,
          },
        ],
      },
      {
        title: "Student Management",
        subMenus: [
          {
            title: "Student Listing",
            path: "/student",
            access: storedScreens.studentListingIndex,
          },
          {
            title: "Attendance",
            path: "/attendance",
            access: storedScreens.attendanceIndex,
          },
          {
            title: "Replace Class List",
            path: "/replaceclasslesson",
            access: storedScreens.attendanceIndex,
          },
          {
            title: "End Class List",
            path: "/endClass",
            access: storedScreens.attendanceIndex,
          },
          {
            title: "Change Class List",
            path: "/changeClass",
            access: storedScreens.attendanceIndex,
          },
        ],
      },
      {
        title: "Schedule",
        subMenus: [
          {
            title: "Time Schedule",
            path: "/scheduleReport",
            access: storedScreens.scheduleTeacherIndex,
          },
          {
            title: "Calendar",
            path: "/calendar",
            access: storedScreens.scheduleTeacherIndex,
          },
        ],
      },
      {
        title: "Document Management",
        subMenus: [
          {
            title: "Document Folder",
            path: "/document",
            access: storedScreens.documentListingIndex,
          },
          {
            title: "Document Files",
            path: "/documentfile",
            access: storedScreens.documentFileIndex,
          },
        ],
      },
      {
        title: "Invoice and Payment",
        subMenus: [
          {
            title: "Invoice",
            path: "/invoice",
            access: storedScreens.invoiceIndex,
          },
        ],
      },
      {
        title: "Referal Management",
        subMenus: [
          {
            title: "Set Referal Fees",
            path: "/referalFees",
            access: storedScreens.referralFeesIndex,
          },
          {
            title: "Referal History",
            path: "/referalHistory",
            access: storedScreens.referralHistoryIndex,
          },
        ],
      },
      {
        title: "Report Management",
        subMenus: [
          {
            title: "Attendance Report",
            path: "/report/attendance",
            access: storedScreens.attendanceReportIndex,
          },
          {
            title: "Revenue Report",
            path: "/report/revenue",
            access: storedScreens.assessmentReportIndex,
          },
          {
            title: "Enrollment Report",
            path: "/report/enrolment",
            access: storedScreens.enrollmentReportIndex,
          },
        ],
      },
      {
        title: "Content Management",
        subMenus: [
          {
            title: "Header & Footer",
            path: "/cms/header",
            access: storedScreens.headerIndex,
          },
          { title: "Home", path: "/cms/home", access: storedScreens.homeIndex },
          {
            title: "Blog",
            path: "/cms/cmsBlog",
            access: storedScreens.blogIndex,
          },
          {
            title: "Testimonial",
            path: "/cms/testimonial",
            access: storedScreens.testimonialIndex,
          },
          {
            title: "About",
            path: "/cms/aboutus",
            access: storedScreens.aboutIndex,
          },
          {
            title: "Courses",
            path: "/cms/CmsCourses",
            access: storedScreens.englishCourseIndex,
          },
          {
            title: "Teachers",
            path: "/cms/teacher",
            access: storedScreens.teacherSaveIndex,
          },
          {
            title: "Products",
            path: "/cms/products",
            access: storedScreens.productSaveIndex,
          },
          {
            title: "Products Items",
            path: "/cms/productsitem",
            access: storedScreens.productImageSaveIndex,
          },
          {
            title: "News & Updates",
            path: "/cms/newsupdate",
            access: storedScreens.newsUpdatesIndex,
          },
          {
            title: "Contact Us",
            path: "/cms/contact",
            access: storedScreens.contactUsIndex,
          },
        ],
      },
      {
        title: "Settings",
        subMenus: [
          { title: "Tax", path: "/tax", access: storedScreens.taxSettingIndex },
          {
            title: "Race",
            path: "/race",
            access: storedScreens.raceSettingIndex,
          },
          {
            title: "Country & Nationality",
            path: "/country",
            access: storedScreens.countrySettingIndex,
          },
          { title: "SHG", path: "/shg", access: storedScreens.shgSettingIndex },
          { title: "Batch Time", path: "/batchtime", access: true },
          {
            title: "Leave Type",
            path: "/leavetype",
            access: storedScreens.leaveSettingIndex,
          },
          {
            title: "ID Type",
            path: "/idType",
            access: storedScreens.idTypeSettingIndex,
          },
          {
            title: "Salary Type",
            path: "/salarytype",
            access: storedScreens.salarySettingIndex,
          },
          {
            title: "Absent Reason",
            path: "/absentreason",
            access: storedScreens.salarySettingIndex,
          },
          {
            title: "Email Template",
            path: "/emailTemplate",
            access: storedScreens.salarySettingIndex,
          },
        ],
      },
      {
        title: "Messaging",
        subMenus: [
          {
            title: "My Messages",
            path: "/messaging",
            access: storedScreens.smsMessageIndex,
          },
          {
            title: "Other Messages",
            path: "/othermessaging",
            access: storedScreens.otherMessageIndex,
          },
          {
            title: "School Announcement",
            path: "/sendNotification",
            access: storedScreens.sendNotificationIndex,
          },
        ],
      },
    ];

    setMenuItems(updatedMenuItems);
  }, [leadCounts, storedScreens]);

  // Handle menu click
  const handleMenuClick = (menuTitle) => {
    setOpenMenus((prev) => {
      const newOpenMenus = {};
      // If the clicked menu is already open, close it; otherwise, open it and close all others
      if (prev[menuTitle]) {
        newOpenMenus[menuTitle] = false;
      } else {
        newOpenMenus[menuTitle] = true;
      }
      return newOpenMenus;
    });
  };

  // Handle home click
  const handleHomeClick = () => {
    setOpenMenus({}); // Close all menus
  };

  // Reset menus when navigating to home
  useEffect(() => {
    if (location.pathname === "/") {
      setOpenMenus({}); // Ensure all menus are closed on home navigation
    }
  }, [location.pathname]);

  return (
    <div className="sidebar">
      <div className="logo-details">
        <span className="logo_name">
          <img src={Logo} alt="logo" width={100} className="img-fluid p-1" />
        </span>
      </div>
      <ul className="nav-links">
        <li>
          <NavLink
            to="/"
            onClick={handleHomeClick}
            className={({ isActive }) => (isActive ? "active activehover" : "")}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id="home-tooltip">Home</Tooltip>}
            >
              <i className="homeIcons">
                <RiDashboardLine />
              </i>
            </OverlayTrigger>
            <span className="links_name text-center"> Home</span>
          </NavLink>
        </li>
        {menuItems.map(
          (item, index) =>
            item.subMenus.some((subMenu) => subMenu.access) && (
              <li key={index}>
                <Nav.Link
                  onClick={() => handleMenuClick(item.title)}
                  className={openMenus[item.title] ? "active activehover" : ""}
                >
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <span className="d-flex align-items-center">
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id={`${item.title}-tooltip`}>
                            {item.title}
                          </Tooltip>
                        }
                      >
                        <span className="px-3">
                          {iconMapping[item.title] || "-"}
                        </span>
                      </OverlayTrigger>
                      <span className="links_name">{item.title}</span>
                    </span>
                    <span className="d-flex align-items-center pe-4">
                      {item.totalCount > 0 && (
                        <span
                          style={{
                            backgroundColor: "#eb862a",
                            color: "white",
                            borderRadius: "50%",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "0.9rem",
                            marginRight: "10px",
                          }}
                        >
                          {item.totalCount}
                        </span>
                      )}
                      {openMenus[item.title] ? (
                        <IoIosRemove size={24} />
                      ) : (
                        <IoIosAdd size={24} />
                      )}
                    </span>
                  </div>
                </Nav.Link>

                <Collapse in={openMenus[item.title]}>
                  <ul className="submenu">
                    {item.subMenus.map(
                      (subMenu, subIndex) =>
                        subMenu.access && (
                          <li key={subIndex}>
                            <NavLink
                              to={subMenu.path}
                              className={({ isActive }) =>
                                isActive ? "active-submenu" : ""
                              }
                            >
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id={`${subMenu.title}-tooltip`}>
                                    {subMenu.title}
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="bx bx-radio-circle-marked"
                                  style={{ fontWeight: "400" }}
                                ></i>
                              </OverlayTrigger>
                              <span className="links_name">
                                {subMenu.title}
                                {subMenu.count > 0 && (
                                  <span
                                    className="badge ms-1"
                                    style={{
                                      fontSize: "0.8rem",
                                      background: "#eb862a",
                                      borderRadius: "50%",
                                      padding: "4px 8px",
                                      color: "white",
                                      minWidth: "22px",
                                      textAlign: "center",
                                      display: "inline-block",
                                      lineHeight: "1.2",
                                    }}
                                  >
                                    {subMenu.count}
                                  </span>
                                )}
                              </span>
                            </NavLink>
                          </li>
                        )
                    )}
                  </ul>
                </Collapse>
              </li>
            )
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
