import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../config/URL";
import { toast } from "react-toastify";

function StudentChangeClassView() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");

  const getData = async () => {
    try {
      const response = await api.get(`/getAllStudentChangeClassById/${id}`);
      setData(response.data);
      const fetchedStatus = response.data.status;
      setStatus(fetchedStatus); // Set initial status
    } catch (error) {
      toast.error("Error Fetching Data ", error);
    }
  };

  const handleStatusToggle = async (newStatus) => {
    try {
      const response = await api.put(
        `/updateChangeClassStatus/${id}?changeClassStatus=${newStatus}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setStatus(newStatus); // Update status only on success
        toast.success("Student change class status updated successfully.");
        await getData(); // Refresh data after successful update
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response?.status === 409) {
        toast.warning(error.response.data.message);
      } else {
        toast.error(error.response?.data?.message || "An error occurred.");
      }
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <div className="container-fluid">
      <ol
        className="breadcrumb my-3 px-2"
        style={{ listStyle: "none", padding: 0, margin: 0 }}
      >
        <li>
          <Link to="/" className="custom-breadcrumb">
            Home
          </Link>
          <span className="breadcrumb-separator"> &gt; </span>
        </li>
        <li>
          Student Management
          <span className="breadcrumb-separator"> &gt; </span>
        </li>
        <li>
          <Link to="/replaceclasslesson" className="custom-breadcrumb">
            Change Class List
          </Link>
          <span className="breadcrumb-separator"> &gt; </span>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          Change Class List View
        </li>
      </ol>
      <div className="card">
        <div
          className="d-flex px-4 justify-content-between align-items-center p-1 mb-4"
          style={{ background: "#f5f7f9" }}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <div className="dot active"></div>
            </div>
            <span className="me-2 text-muted">View Change Class List</span>
          </div>
          <div className="my-2 pe-3 d-flex align-items-center">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check btn-check-status"
                name="status"
                id="approve"
                autoComplete="off"
                checked={status === "APPROVED"}
                onChange={() => handleStatusToggle("APPROVED")}
              />
              <label
                className="btn btn-outline-orange btn-sm"
                htmlFor="approve" // Changed from class_asseigend to approve
              >
                Approve
              </label>

              <input
                type="radio"
                className="btn-check btn-check-status"
                name="status"
                id="reject"
                autoComplete="off"
                checked={status === "CANCEL"}
                onChange={() => handleStatusToggle("CANCEL")}
              />
              <label className="btn btn-outline-orange btn-sm" htmlFor="reject">
                Cancel
              </label>
            </div>
            {status === "APPROVED" && (
              <Link
                to={`/changeClassAssigned?centerId=${data.centerId}&studentId=${data.studentId}`}
              >
                <button type="button" className="btn btn-button btn-sm mx-2">
                  Class Assigned
                </button>
              </Link>
            )}
            <Link to="/changeClass">
              <button type="button " className="btn btn-sm btn-border mx-2">
                Back
              </button>
            </Link>
          </div>
        </div>
        <div className="container-fluid px-4">
          <div className="row pb-3">
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Center Name</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm">
                    : {data.centerName || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Last Lesson Date</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.lastLessonDate || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Student Name</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.studentName || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Student ID</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.studentUniqueId || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Current Course</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.currentCourse || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Current Class</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.currentClass || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Prefer Day</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.preferdays || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row mb-2">
                <div className="col-3">
                  <p className="fw-medium">Preferred Timing</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    :{" "}
                    {data.preferTiming
                      ? new Date(
                          `1970-01-01T${data.preferTiming}:00`
                        ).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Reason</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.reason || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Other Reason</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.otherReason || ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="row  mb-2">
                <div className="col-3  ">
                  <p className="fw-medium">Centre Remark</p>
                </div>
                <div className="col-9">
                  <p className="text-muted text-sm d-flex text-break">
                    : {data.centerRemark || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentChangeClassView;
